import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/a.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/b.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/c.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/d.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/lipread.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/natural.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/signlang.png");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/staffai/components/images/voice.png");
;
import(/* webpackMode: "eager", webpackExports: ["IconGitHub"] */ "/home/ceshi/code/aigxion/apps/staffai/components/ui/icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/client/link.js");
